// require('./bootstrap');

// Import all of CoreUI's JS
const coreui = window.coreui = require('@coreui/coreui');

// Import all of SweetAlert2 JS
const Swal = window.Swal = require('sweetalert2');

// Ventana modal perfil del usuario
document.addEventListener('livewire:load', function () {
    const modal = new coreui.Modal('#perfilModal');

    /**
     * Cierra la ventana modal
     */
    window.addEventListener('closeModalPerfil', event => {
        modal.hide();
    });

    /* Muestra un mensaje en un toast Sweetalert2 */
    window.addEventListener('showMessagePerfil', event => {
        let message = event.detail.message;
        let icon = event.detail.icon;

        Swal.fire({
            toast: true,
            position: 'top-start',
            iconColor: 'white',
            customClass: {
                popup: 'colored-toast',
            },
            showConfirmButton: false,
            timer: 2500,
            timerProgressBar: true,
            icon: (icon == undefined) ? 'success' : icon,
            title: message,
        });
    });
});

// Muestra la web a pantalla completa
function toggleFullscreen() {
    if (!document.fullscreenElement) {
        document.documentElement.requestFullscreen();
    } else {
        document.exitFullscreen();
    }
}

// Botón pantalla completa
fullscreenBtn = document.getElementById('fullscreenBtn');
if (fullscreenBtn) {
    fullscreenBtn.addEventListener("click", toggleFullscreen);
}

// Botón arriba
(function () {
    const ishow = 115; //a partir de que punto del scroll vertical de la ventana mostrará el botón
    const divtop = document.getElementById("div-totop");
    const btntop = document.getElementById("btn-totop");

    if (btntop && divtop) {
        btntop.addEventListener('click', function () {
            window.scrollTo(0, 0);
        });

        window.addEventListener("scroll", function () {
            if (document.documentElement.scrollTop > ishow) {
                divtop.style.display = "inherit";
            }
            else {
                divtop.style.display = "none";
            }
        });
    }
})();

// Recuerda el estado del sidebar según el usuario
let mySidebar = document.getElementById("sidebar");
if (mySidebar) {
    let sidebarCollapseButton = document.getElementsByClassName("sidebar-toggler")[0];
    let ckey = `sidebarStatus_${document.getElementById("statusId").value}`;

    // Function to set a Cookie
    function setCookie(cname, cvalue, exdays) {
        const d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        let expires = "expires=" + d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/;SameSite=Strict;";
    }

    // Obtiene el tamaño del viewport
    function getViewport() {
        const width = Math.max(
            document.documentElement.clientWidth,
            window.innerWidth || 0
        )

        // xs - size less than or 575.98
        if (width <= 575.98) return 'xs'
        // sm - size between 576 - 767.98
        if (width >= 576 && width <= 767.98) return 'sm'
        // md - size between 768 - 991.98
        if (width >= 768 && width <= 991.98) return 'md'
        // lg - size between 992 - 1199.98
        if (width >= 992 && width <= 1199.98) return 'lg'
        // xl - size between 1200 - 1399.98
        if (width >= 1200 && width <= 1399.98) return 'xl'

        // xxl- size greater than 1399.98
        return 'xxl'
    }

    let myViewport = getViewport();

    window.addEventListener("resize", () => myViewport = getViewport());

    // Establece el valor del Cookie al minimizar o mostrar el sidebar
    sidebarCollapseButton.addEventListener('click', function (e) {
        if (mySidebar.classList.contains('sidebar-narrow-unfoldable')) {
            setCookie(ckey, "sidebar-narrow-unfoldable", 30);
        } else {
            setCookie(ckey, "", 30);
        }
    });

    // Establece el valor del Cookie al ocultar o mostrar el sidebar
    mySidebar.addEventListener('hidden.coreui.sidebar', function () {
        if (myViewport === 'lg' || myViewport === 'xl' || myViewport === 'xxl') {
            setCookie(ckey, "hide", 30);
        }
    });

    // Establece el valor del Cookie al ocultar o mostrar el sidebar
    mySidebar.addEventListener('shown.coreui.sidebar', function () {
        if (myViewport === 'lg' || myViewport === 'xl' || myViewport === 'xxl') {
            setCookie(ckey, "", 30);
        }
    });

}
